<template>
  <v-card class="custom-field-popup-card">
    <v-card-title class="px-5 pt-4 pb-4">
      <div> {{ tempWebsite.id ? 'Edit' : 'Create a' }} Web Tracking Script</div>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-6 pt-6 pb-10">
      <CustomTextInput
        v-model="tempWebsite.name"
        header="Name"
        placeholder="Client Site"
        class="mt--2"
      />
      <CustomTextInput
        v-model="tempWebsite.url"
        header="URL"
        placeholder="https://"
        class="mt--2"
        :required="tempWebsite.type === 'internal'"
      />
      <CustomDropdown
        v-model="tempWebsite.type"
        header="Website Type"
        placeholder="Select a website type"
        :items="websiteTypes"
        item-text="title"
        item-value="value"
      />
    </v-card-text>

    <v-card-actions
      class="divider-with-shadow d-flex justify-end px-6 pb-3 pt-3"
    >
      <div
        class="simple-text-button button--gray mx-8 d-flex align-center"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "AddWebTrackingScriptDialog",
  components: { CustomDropdown, CustomTextInput },
  props: {
    script: {
      default: () => [],
    },
  },
  data() {
    return {
      tempWebsite: {},
      websiteTypes: [
        {
          title: "Internal (Web Tracking Capable)",
          value: "internal",
        },
        {
          title: "Third Party",
          value: "third-party",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.tempWebsite = { ...this.script };

    if (!this.tempWebsite.type) {
      this.tempWebsite.type = 'internal';
    }
  },
  methods: {
    save() {
      this.$emit("edit", this.tempWebsite);
    },
  },
};
</script>
