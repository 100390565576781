<template>
  <v-container class="web-tracking-scripts mt-5 px-10" fluid>
    <PageHeader
      header-text="Web Tracking Scripts"
      regular-button-text="ADD WEB SCRIPT"
      class="mb-7"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="
        currentWebsite = {};
        editDialog = true;
      "
    >
      <template #subheader>
        <div>View available website tracking scripts</div>
      </template>
    </PageHeader>

    <div class="scripts-table">
      <v-simple-table>
        <thead>
          <tr class="scripts-table__header">
            <th>Name</th>
            <th>URL</th>
            <th>Pulse Site ID</th>
            <th>Created Date</th>
            <th v-if="$store.getters['user/hasWritePermission']" />
            <th v-if="$store.getters['user/hasNoContactDataRole']" />
          </tr>
        </thead>
        <tbody class="search-table__content">
          <tr v-for="(item, i) in websites" :key="i">
            <td>
              {{ item.name ? item.name : '-' }}
            </td>
            <td>
              {{ item.url ? item.url : '-' }}
            </td>
            <td>
              {{ item.matomoSiteId ? item.matomoSiteId : '-' }}
            </td>
            <td>
              <div :title="getDateWithDayNameAndTime(item.createdAt)">
                {{ getDate(item.createdAt) }}
              </div>
            </td>
            <td v-if="$store.getters['user/hasWritePermission']">
              <div class="wide-field d-flex justify-end ml-lg-15 pl-xl-15">
                <div
                  class="simple-text-button button--blue"
                  @click="
                    currentWebsite = item;
                    editDialog = true;
                  "
                >
                  Edit
                </div>
              </div>
            </td>
            <td v-if="$store.getters['user/hasNoContactDataRole']" class="table-button-cell">
              <template v-if="item.type === 'internal'">
                <v-btn
                  class="elevation-0 outlined-btn px-12"
                  height="34px"
                  width="150px"
                  plain
                  @click="currentWebsite = item; viewCodeDialog = true;"
                >
                  View Tracking Code
                </v-btn>
              </template>
              <template v-else>
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      color="yellow"
                      v-on="on"
                    >
                      {{ item.type }}
                    </v-chip>
                  </template>
                  <span>Only websites of type "internal" can have web tracking configured via a tracking code.</span>
                </v-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-dialog v-model="editDialog" scrollable max-width="560px">
      <EditWebTrackingScriptDialog
        v-if="editDialog"
        :script="currentWebsite"
        @dismiss="editDialog = false"
        @edit="(script) => createWebsite(script)"
      />
    </v-dialog>
    <v-dialog v-model="viewCodeDialog" scrollable max-width="860px">
      <ViewTrackingCodeDialog
        v-if="viewCodeDialog"
        :script="currentWebsite"
        @dismiss="viewCodeDialog = false"
        @send-to-developer="(script, email, siteType) => sendToDeveloper(script, email, siteType)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import EditWebTrackingScriptDialog from "@/views/WebTracking/components/EditWebTrackingScriptDialog";
import ViewTrackingCodeDialog from "@/views/WebTracking/components/ViewTrackingCodeDialog";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "WebTrackingScriptsView",
  metaInfo: {
    title: 'Web Tracking'
  },
  components: {
    PageHeader,
    EditWebTrackingScriptDialog,
    ViewTrackingCodeDialog,
  },
  mixins: [datesMixin],
  data() {
    return {
      editDialog: false,
      viewCodeDialog: false,
      currentWebsite: {},
      websites: {},
    };
  },
  created() {
    this.getWebsites();
  },
  methods: {
    async getWebsites() {
      const resp = await this.$rest.website.get_collection();
      this.websites = resp.data.items;
    },
    async createWebsite(website) {
      try {
        if (website.id) {
          await this.$rest.website.put_resource(website.id, website);
        } else {
          await this.$rest.website.post_resource(website);
        }
        await this.getWebsites();
        this.editDialog = false;
      } catch (e) {
        if (e.response.data.code === '400')
          this.$notifier.error(e.response.data.errors[0].error);
      }
    },
    async sendToDeveloper(script, email, siteType) {
      await this.$rest.website.send_email_to_developer(script.id, {
        email,
        siteType
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-button-cell {
  width: 166px !important;
  padding: 22px 16px !important;
  padding-right: 0 !important;
  margin: 30px 0 !important;
}
.wide-field {
  // width: 20vw;
}


.scripts-table {
  // .v-data-table {
  &__header {
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
  }

  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    // table border colors
    tr > td {
      border-bottom: solid 1px #dee7ee;
    }
    tr:hover {
      background: inherit !important;
    }
  }
  // }
}

@import "@/scss/contact-info-table.scss";

</style>
