<template>
  <v-card class="custom-field-popup-card">
    <v-card-title class="px-5 pt-4 pb-4">
      <div>View tracking code</div>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-6 pt-6 pb-10 ">
      <h3 class="mb-3">
        What type of website is this tracking code for?
      </h3>
      <div class="text-center">
        <v-btn-toggle
          mandatory
        >
          <v-btn
            v-for="(details, type) in siteTypes"
            :key="type"
            @click="siteType = type"
          >
            {{ details.label }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <div class="mb-6 mt-3 text--black" v-html="siteTypes[siteType].description" />

      <div style="background-color: #ecf1f5; padding: 10px">
        <pre v-html="trackingCode" />
      </div>

      <div class="notification-menu__header mt-7">
        Send to Developer
      </div>
      <validation-observer v-if="!email_sent" ref="textInput">
        <div class="d-flex align-center">
          <CustomTextInput
            v-model="email"
            placeholder="Developer email address"
            class="mt--2 flex-grow-1"
            required
            email
            :maxlength="150"
          />

          <div class="simple-text-button button--blue mx-7 mt-2" @click="send">
            Send
          </div>
        </div>
      </validation-observer>
      <div v-else class="sent-email-block">
        <v-icon size="16" color="#2b84eb" class="mr-2">
          mdi-check-circle
        </v-icon>
        <div class="comment-text">
          {{ `Sent to ${email}` }}
        </div>
      </div>
    </v-card-text>

    <v-card-actions
      class="divider-with-shadow d-flex justify-end px-6 pb-3 pt-3"
    >
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="$emit('dismiss')"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "ViewTrackingCodeDialog",
  components: { CustomTextInput },
  props: {
    script: {
      default: () => [],
    },
  },
  data() {
    return {
      email: null,
      email_sent: false,
      siteType: 'multi_page_app',
      store_tracking_code_here: null,
      siteTypes: {
        multi_page_app: {
          label: 'Multi-Page App',
          file: 'track.js',
          description: 'A multi-page app is a common set up for websites where the content on the page is rendered by a web server. ' +
            'A good way to know if the website is a multi-page app is if you notice that your browser is reloading when going to a new page on the website.',
        },
        spa: {
          label: 'Single-Page App (SPA)',
          file: 'track-spa.js',
          description: 'Single-page apps are becoming a new standard over the last few years. A good way to know if the ' +
            'website is a single-page app is if you notice that your browser is not reloading when going to a new page.',
        },
        spa_hash: {
          label: 'Single-Page App (SPA) using Hashed URLs',
          file: 'track-spa-hash.js',
          description: 'Single-page apps are becoming a new standard over the last few years. A good way to know if the ' +
            'website is a single-page app is if you notice that your browser is not reloading when going to a new page. ' +
            'This specific option is for websites that use a specific type of URL structure to go to other pages on the website.<br>' +
            'You should select this option if you notice that the structure is something similar to: <br>' +
            '<strong>https://www.yoursite.com/#/new-page</strong> or <strong>https://www.yoursite.com/#/sign-up.</strong><br>' +
            'If you notice the hash (#) followed by what looks like a page URL, this is the option for you.',
        },
      }
    };
  },
  computed: {
    trackingCode() {
      if (!this.script.matomoSiteId || !this.siteType || !this.siteTypes[this.siteType].file) {
        return '<pre>Unable to generate a tracking code. Please refresh and try again.</pre>';
      }

      const trackingCodeFile = this.siteTypes[this.siteType].file;

      return `<pre>&lt;!-- Pulse Tracking --&gt;<br style="box-sizing: inherit;">` +
        `&lt;script type=&quot;text/javascript&quot;&gt;<br style="box-sizing: inherit;">` +
        `  var pulseSiteID = "${this.script.matomoSiteId}";<br style="box-sizing: inherit;">` +
        `&lt;/script&gt;<br style="box-sizing: inherit;">` +
        `&lt;script type=&quot;text/javascript&quot; src=&quot;//tracking.pulsehealth.tech/${trackingCodeFile}&quot;&gt;&lt;/script&gt;` +
        `<br style="box-sizing: inherit;">` +
        `&lt;noscript&gt;&lt;p&gt;<br style=\\"box-sizing: inherit;\\">` +
        `  &lt;img src=&quot;//tracking.pulsehealth.tech/track-noscript.php?idsite=${this.script.matomoSiteId}&amp;rec=1&quot; style=&quot;border:0;&quot; /&gt;` +
        `<br style=\\"box-sizing: inherit;\\">&lt;/p&gt;&lt;/noscript&gt;<br style="box-sizing: inherit;">` +
        `&lt;!-- End Pulse Tracking --&gt;</pre>`;
    },
  },
  methods: {
    async send() {
      try {
        if (await this.validate()) {
          this.$emit("send-to-developer", this.script, this.email, this.siteType);
          this.email_sent = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async validate() {
      return await this.$refs.textInput.validate();
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
.sent-email-block {
  height: 52px;
  display: flex;
  align-items: center;
}
</style>
